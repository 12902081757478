import type React from 'react'
import { HStack, Spacer } from '~/components/ui'
import { AppLogo } from './AppLogo'
import { AppNavMenu } from './AppNavMenu'
import { AppProfileDropdown } from './AppProfileDropdown'

interface AppHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: React.ReactNode
}
export const AppHeader = ({ header, ...rest }: AppHeaderProps) => {
  return (
    <div
      className="sticky inset-x-0 top-0 z-10 flex h-[3rem] items-center gap-2 bg-card px-4 py-2 text-card-foreground md:gap-4"
      {...rest}
    >
      <HStack>
        <AppNavMenu />
        <AppLogo />
      </HStack>

      {header}

      <Spacer />

      <AppProfileDropdown />
    </div>
  )
}
