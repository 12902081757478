import { Stack } from '~/components/ui'

export const AppFooter = ({ ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="bg-card py-4 text-sm text-card-foreground" {...rest}>
      <div className="container max-w-6xl py-4 text-center">
        <Stack>
          <div className="text-center">
            <div className="flex flex-row flex-wrap justify-center gap-4">
              <a
                className="hover:underline"
                href="https://www.dailove.com/support/contact.html"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ
              </a>

              <span>|</span>

              <a
                className="hover:underline"
                href="https://www.dailove.com/privacy.html"
                target="_blank"
                rel="noreferrer"
              >
                プライバシー
              </a>

              <span>|</span>

              <a className="hover:underline" href="https://www.dailove.com/" target="_blank" rel="noreferrer">
                運営会社
              </a>
            </div>
          </div>

          <p>
            &copy; {new Date().getFullYear()}{' '}
            <a
              className="whitespace-nowrap font-bold hover:underline"
              href="https://www.dailove.com/"
              target="_blank"
              rel="noreferrer"
            >
              DIA RUBBER CO.,LTD
            </a>{' '}
            <span className="whitespace-nowrap">All Rights Reserved.</span>
          </p>
        </Stack>
      </div>
    </div>
  )
}
