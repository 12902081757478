import type { Profile, User } from '@prisma/client'
import { useRouteLoaderData } from '@remix-run/react'
import type { loader } from '~/root'

export const useAuth = () => {
  const rootLoaderData = useRouteLoaderData<typeof loader>('root')

  return {
    user: rootLoaderData?.user as User | undefined,
    profile: rootLoaderData?.profile as Profile | undefined,
  }
}
