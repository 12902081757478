import {
  BookUserIcon,
  DownloadIcon,
  FlaskConicalIcon,
  GaugeIcon,
  MegaphoneIcon,
  MenuIcon,
  SearchIcon,
  SquareUserRoundIcon,
} from 'lucide-react'
import { createContext, useState } from 'react'
import { $path } from 'remix-routes'
import { Separator, Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger, Stack } from '~/components/ui'
import { useAuth } from '~/hooks/useAuth'
import { AppLogo, AppNavItem } from '.'

export const AppNavContext = createContext<{ isOpen: boolean; onSelect: (() => void) | null }>({
  isOpen: false,
  onSelect: null,
})

export const AppNavMenu = () => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  const handleOnSelect = () => {
    setIsOpen(false)
  }

  return (
    <Sheet open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <SheetTrigger onClick={() => setIsOpen(true)} aria-label={`${isOpen ? 'Close' : 'Open'} menu`}>
        <MenuIcon />
      </SheetTrigger>
      <SheetContent side="left" className="px-4 sm:max-w-64">
        <SheetHeader>
          <SheetTitle className="mx-auto text-center">
            <AppLogo />
          </SheetTitle>
        </SheetHeader>

        <AppNavContext.Provider value={{ isOpen, onSelect: handleOnSelect }}>
          <Stack className="mt-4 flex-1">
            <AppNavItem to="/lookup" icon={<SearchIcon />} label="検索" />

            {user && <AppNavItem to="/download-history" icon={<DownloadIcon />} label="ダウンロード履歴" />}
            {user && <AppNavItem to="/profile" icon={<SquareUserRoundIcon />} label="プロフィール" />}

            <Separator />

            <AppNavItem to="/news" icon={<MegaphoneIcon />} label="お知らせ・更新情報" />
            <AppNavItem to="/chemicals" icon={<FlaskConicalIcon />} label="検索可能な薬品" />

            {user?.role === 'admin' && (
              <Stack gap="2">
                <Separator />
                <div className="text-sm font-bold text-red-500">管理</div>
                <AppNavItem to={$path('/admin')} icon={<GaugeIcon />} label="管理ダッシュボード" />

                <AppNavItem to={$path('/admin/users')} icon={<BookUserIcon />} label="ユーザリスト" />
              </Stack>
            )}
          </Stack>
        </AppNavContext.Provider>
      </SheetContent>
    </Sheet>
  )
}
