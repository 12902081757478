import { Link } from '@remix-run/react'
import { $path } from 'remix-routes'
import {
  Avatar,
  AvatarFallback,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  HStack,
} from '~/components/ui'
import { useAuth } from '~/hooks/useAuth'

export const AppProfileDropdown = () => {
  const { user, profile } = useAuth()

  if (!user) return <></>

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="cursor-pointer">
        <Avatar>
          <AvatarFallback>{(profile?.name ?? '未登録')[0]}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          <HStack>
            <Avatar>
              <AvatarFallback>{(profile?.name ?? '未登録')[0]}</AvatarFallback>
            </Avatar>
            <div>
              <p className="font-semibold">{profile?.name ?? '未登録'}</p>
              <p className="font-xs mt-1 text-gray-500">{profile?.email}</p>
            </div>
          </HStack>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to={$path('/profile')}>プロフィール</Link>
        </DropdownMenuItem>
        <DropdownMenuItem className="text-destructive" asChild>
          <Link to={$path('/signout')}>サインアウト</Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
