import { NavLink } from '@remix-run/react'
import * as React from 'react'
import { cn } from '~/utils/cn'
import { AppNavContext } from './AppNavMenu'
import { Button } from './ui'

interface AppNavItemProps {
  to: string
  label: string
}

interface AppDesktopItemProps extends AppNavItemProps {
  icon?: React.ReactNode
}

export const AppNavItem = (props: AppDesktopItemProps) => {
  const { icon, label } = props
  const { onSelect } = React.useContext(AppNavContext)

  return (
    <NavLink className="group" to={props.to} end>
      <Button
        variant="ghost"
        className={cn(
          'w-full justify-start group-aria-[current]:bg-secondary group-aria-[current]:text-secondary-foreground',
        )}
        onClick={() => onSelect?.()}
      >
        {icon && (
          <span className="text-md mr-2" aria-hidden>
            {icon}
          </span>
        )}
        <span>{label}</span>
      </Button>
    </NavLink>
  )
}
